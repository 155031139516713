<template>
  <v-expansion-panels v-model="panel" multiple flat tile>
    <v-expansion-panel>
      <v-expansion-panel-header style="padding-left: 6px;">        
        <div>
          <span class="secondary--text text-subtitle-1 mb-8">Code Generator</span>
          <v-spacer></v-spacer><span class="caption">model: <span class="font-weight-bold">{{ this.model.modelType }}</span> • API: <span class="font-weight-bold">{{ this.model.apiType }}</span></span>
        </div>

      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-tabs color="primary" v-model="tab" right dense>
          <v-tab v-for="lang in languages" :key="lang.tab">
            <v-icon small left>{{ lang.icon }}</v-icon>
            <span class="caption">{{ lang.tab }}</span>
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="lang in languages" :key="lang.tab">
              <v-layout wrap class="justify-center">
                <v-flex lg5 md5 sm12>
                  <v-container fluid>
                    <v-radio-group v-model="radioGroup" dense class="mb-4">
                      <v-radio
                        v-for="option in radioGroupOptions"
                        :key="option.id"
                        :label="`${option.description}`"
                        :value="option.id"
                      ></v-radio>
                    </v-radio-group>

                    <v-text-field                    
                      v-if="radioGroup !== 'delete'"
                      dense
                      :label="`User ${timeBasedLimitsEnabled ? '(required)': '(optional)'}`"
                      v-model="user"
                      outlined
                    ></v-text-field>

                    <v-text-field                    
                      v-if="radioGroup === 'topUpCredits'"
                      dense
                      type="number"
                      label="Amount"
                      v-model="amount"
                      outlined
                    ></v-text-field>

                    <v-text-field                    
                      v-if="radioGroup === 'generate' || radioGroup === 'chat'"
                      dense
                      label="Model"
                      v-model="modelName"
                      outlined
                    ></v-text-field>

                    <v-text-field                    
                    v-if="radioGroup === 'generate' || radioGroup === 'chat'"
                      dense
                      label="Prompt"
                      v-model="prompt"
                      outlined
                    ></v-text-field>
                    
                    <v-tooltip v-if="radioGroup === 'generate' || (radioGroup === 'chat' && model.apiType === 'openai')" top>
                      <template v-slot:activator="{ on: tooltip }">
                        <span v-on="{ ...tooltip }">
                          <v-checkbox
                            v-model="stream"
                            class="mb-5"
                            label="Streaming response"
                            color="primary"                      
                            hide-details
                          ></v-checkbox>
                        </span>
                      </template>
                      Stream the response back to the client as it is generated.                   
                    </v-tooltip>                    
                    
                    <!-- <v-select
                      v-if="radioGroup === 'generate'"
                      class=""
                      outlined
                      dense
                      v-model="priority"
                      :items="priorityClass"
                      item-text="text"
                      item-value="value"
                      label="Priority class"
                    ></v-select> -->
                    
                    <v-text-field
                      v-if="radioGroup === 'delete'"
                      dense
                      label="Gen ID"
                      v-model="imageId"
                      outlined
                    ></v-text-field>
                  </v-container>
                </v-flex>
                <v-flex lg7 md7 sm12>
                  <markup
                    class="mt-5"
                    :language="lang.lang"
                    :code="snippet(lang, false)"
                    :copyCode="snippet(lang, true)"
                    :inline="false"
                  ></markup>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import { HTTPSnippet } from '@readme/httpsnippet'
import Markup from '../helpers/Markup';

const generate = 'generate'
const chat = 'chat'

const remainingGenerations = 'remaining'
const listGeneratedImages = 'list'
const topUpCredits = 'topUpCredits'
const getCredits = 'getCredits'
// const deleteGeneratedImage = 'delete'

export default {
  components: {
    Markup,
  },

  data: () => ({
    panel: [0],
    radioGroup: chat,
    tab: {
      lang: 'shell',
      tab: 'Shell',
    },
    languages: [
      { lang: 'shell', tab: 'Shell', icon: 'mdi-console-line' },
      { lang: 'javascript', tab: 'JavaScript', icon: 'mdi-language-javascript' },
      { lang: 'python', tab: 'Python', icon: 'mdi-language-python' },
    ],    
    user: 'default',
    modelName: 'llama2',
    prompt: 'Here is a story about dinosaurs',
    stream: true,
    amount: 100,
    imageId: '',
    status: '',
    statuses: [
      { text: 'Pending', value: 'pending' },
      { text: 'Processing', value: 'processing' },
      { text: 'Completed', value: 'completed' },
      { text: 'Failed', value: 'failed' },
    ],
    priorityClass: [
      { text: 'Not set', value: '' },
      { text: 'High', value: 'high' },
      { text: 'Medium', value: 'medium' },
      { text: 'Low', value: 'low' },      
    ],
    priority: ''
  }),

  props: {
    apiToken: String,
    model: Object,    
  },

  watch: {
    model (val, oldVal) {
      if (val.id === oldVal.id) {
        return
      }

      if (val.apiType === 'ollama') {
        this.modelName = 'llama2'
      } else {
        this.modelName = 'gpt-3.5-turbo'
      }
    }
  },

  mounted() {
    // Initial model setup
    if (this.model.apiType === 'ollama') {
      this.modelName = 'llama2'
    } else {
      this.modelName = 'gpt-3.5-turbo'
    }
  },

  computed: {
    apiBase() {
      if (this.model.modelHost === '') {
        return `${window.location.protocol}//${window.location.host}`
      }
      return `${window.location.protocol}//${this.model.modelHost}:${window.location.port}`
    },
    timeBasedLimitsEnabled() {
      if (this.model === '') {
        return false
      }      
      return this.model.rules.timeBasedLimits
    },
    radioGroupOptions() {
      let base = [
        {
          id: chat,
          description: 'Chat'
        }
      ] 

      if (this.model.apiType === 'ollama') {
        base.push({
          id: generate,
          description: 'Generate'
        })
      }
            
      base.push({
        id: listGeneratedImages,
        description: 'List conversations'
      })  

      if (this.model.rules.timeBasedLimits) {
        base.push({
          id: remainingGenerations,
          description: 'Remaining generations for users'
        })
      }

      if (this.model.rules.credits) {      
        base.push({
          id: topUpCredits,
          description: 'Adjust balance'
        })

        base.push({
          id: getCredits,
          description: 'Get credits balance'
        })
      }

      return base
    },
  },

  methods: {
    snippet(lang, withCredentials) {
      let apiToken = '**********'
      if (withCredentials) {
        apiToken = this.apiToken        
      }
    
      if (this.radioGroup === remainingGenerations) {
        return this.remainingGenerationsSnippet(lang, this.model.name, this.user, apiToken)
      }

      if (this.radioGroup === generate) {
        return this.generateSnippet(lang, this.user, this.priority, apiToken)
      }
      
      if (this.radioGroup === chat) {
        return this.chatSnippet(lang, this.user, this.priority, apiToken)
      }
      
      if (this.radioGroup === topUpCredits) {
        return this.adjustBalanceSnippet(lang, this.user, this.amount, apiToken)
      }

      if (this.radioGroup === getCredits) {
        return this.getBalanceSnippet(lang, this.user, apiToken)
      }

      return this.listImagesSnippet(lang, this.model.name, this.status, this.user, apiToken)
    },

    generateSnippet(lang, user, priority, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]

      if (user.length > 0) {
        headers.push({
          name: 'X-User',
          value: user
        })
      }
      
      if (priority.length > 0) {
        headers.push({
          name: 'X-Priority',
          value: priority
        })
      }

      var url = ''
      var body = ''

      switch (this.model.apiType) {
        case 'openai':
          url = `${this.apiBase}/v1/chat/completions`
          body = `{
    "model": "${this.modelName}",
    "stream": ${this.stream},
    "messages": [
      {
        "role": "system",
        "content": "You are a helpful assistant."
      },
      {
        "role": "user",
        "content": "${this.prompt}"
      }
    ]
  }`
          break
        case 'ollama':
          url = `${this.apiBase}/api/generate`
          body = `{
	"model": "${this.modelName}",
	"stream": ${this.stream},
  "prompt": "${this.prompt}"
}`
          break      
      }

      const snippet = new HTTPSnippet({
        method: 'POST',
        url: url,
        headers: headers,
        postData: {
          text: body,
          mimeType: "application/json"
        }
      })

      return this.convertSnippet(lang, snippet)
    },

    chatSnippet(lang, user, priority, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]

      if (user.length > 0) {
        headers.push({
          name: 'X-User',
          value: user
        })
      }
      
      if (priority.length > 0) {
        headers.push({
          name: 'X-Priority',
          value: priority
        })
      }

      var url = ''
      var body = ''

      switch (this.model.apiType) {
        case 'openai':
          url = `${this.apiBase}/v1/chat/completions`
          body = `{
    "model": "${this.modelName}",
    "stream": ${this.stream},
    "messages": [
      {
        "role": "system",
        "content": "You are a helpful assistant."
      },
      {
        "role": "user",
        "content": "Hello!"
      }
    ]
  }`
          break
        case 'ollama':
          url = `${this.apiBase}/api/chat`
          body = `{
	"model": "${this.modelName}",
	"messages": [
      {
        "role": "system",
        "content": "You are a helpful assistant."
      },
      {
        "role": "user",
        "content": "${this.prompt}"
      }
    ]  
}`
          break      
      }

      const snippet = new HTTPSnippet({
        method: 'POST',
        url: url,
        headers: headers,
        postData: {
          text: body,
          mimeType: "application/json"
        }
      })

      return this.convertSnippet(lang, snippet)
    },

    remainingGenerationsSnippet(lang, model, user, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'GET',
        url: `${window.location.protocol}//${window.location.host}/api/remaining/generations`,
        headers: headers,
        queryString: [
          {
            name: 'user',
            value: user
          },
          {
            name: 'model',
            value: model
          },
        ]
      })

      return this.convertSnippet(lang, snippet)
    },

    listImagesSnippet(lang, model, status, user, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]

      let query = []

      if (user.length > 0) {
        query.push({
          name: 'user',
          value: user
        })
      }

      if (model !== '') {
        query.push({
          name: 'model',
          value: model
        })
      }
      
      // if (status !== '') {
      //   query.push({
      //     name: 'status',
      //     value: status
      //   })
      // }

      const snippet = new HTTPSnippet({
        method: 'GET',
        url: `${window.location.protocol}//${window.location.host}/api/chat/completions`,
        headers: headers,
        queryString: query
      })

      return this.convertSnippet(lang, snippet)
    },

    adjustBalanceSnippet(lang, user, amount, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'PUT',
        url: `${window.location.protocol}//${window.location.host}/api/credits`,
        headers: headers,
        postData: {
          text: `{
            "user": "${user}",	
            "amount": ${amount}
          }`,
          mimeType: "application/json"
        }
      })

      return this.convertSnippet(lang, snippet)
    },

    getBalanceSnippet(lang, user, apiToken) {
      let headers = [
        {
          name: 'Content-Type',
          value: 'application/json'
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'GET',
        url: `${window.location.protocol}//${window.location.host}/api/credits`,
        headers: headers,
        queryString: [
          {
            name: 'user',
            value: user
          },         
        ]
      })

      return this.convertSnippet(lang, snippet)
    },

    deleteImagesSnippet(lang, id, apiToken) {
      let headers = [
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
        {
          name: 'Authorization',
          value: `Bearer ${apiToken}`
        },
      ]     

      const snippet = new HTTPSnippet({
        method: 'DELETE',
        url: `${window.location.protocol}//${window.location.host}/api/images/generations/${id}`,
        headers: headers,        
      })

      return this.convertSnippet(lang, snippet)
    },

    convertSnippet(lang, snippet) {
      const options = { indent: '\t' };

      switch (lang.lang) {
        case 'shell':
          return snippet.convert('shell', 'curl', options)
        case 'javascript':
          return snippet.convert('node', 'axios', options)
        case 'python':
          return snippet.convert('python', 'requests', options)
      }
    }
  }
}

</script>